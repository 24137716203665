






























































import { Vue, Component, Prop } from "vue-property-decorator";
import { ConfirmedOrderProperties } from "./ConfirmedOrderAView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { Options } from "autonumeric";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CustomerPaymentService from "@/services/CustomerPaymentService";
import CustomerPaymentAttachmentService from "@/services/CustomerPaymentAttachmentService";
import AutoNumericUtil from "@/utilities/AutoNumericUtil";

class CheckoutDialogProperties {
    totalAmount: number = 0;
    visible: boolean = false;
}

export { CheckoutDialogProperties };

@Component({
    data: () => ({
        paymentMethod: "Receipt",
        attachments: [],
        receiptPayment: 0,
        walletPayment: 0,
        saving: false,
    }),
})
export default class CheckoutDialogView extends Vue {
    @Prop({ default: new CheckoutDialogProperties() })
    private properties: CheckoutDialogProperties;
    @Prop() private confirmedOrder: ConfirmedOrderProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerPaymentService = new CustomerPaymentService();
    private inputReceiptPayment: HTMLInputElement;
    private inputWalletPayment: HTMLInputElement;

    public get paymentOptions() {
        return [
            {
                text: this.$t("text.wallet"),
                value: "Wallet"
            },
            {
                text: this.$t("text.receipt"),
                value: "Receipt",
            },
            {
                text: this.$t("text.mixed"),
                value: "Mixed"
            }
        ];
    }

    public get isReceipt() {
        const paymentMethod = this.$data.paymentMethod;
        return paymentMethod === 'Receipt' || paymentMethod === 'Mixed';
    }

    public get isWallet() {
        const paymentMethod = this.$data.paymentMethod;
        return paymentMethod === 'Wallet' || paymentMethod === 'Mixed';
    }

    public paymentMethodChanged() {
        const paymentMethod = this.$data.paymentMethod;
        if (paymentMethod === 'Mixed') {
            AutoNumericUtil.set(this.inputReceiptPayment, 0);
            AutoNumericUtil.set(this.inputWalletPayment, 0);            
            this.$data.receiptPayment = "0.00";
            this.$data.walletPayment = "0.00";
        } else if (paymentMethod === 'Receipt') {
            AutoNumericUtil.set(this.inputReceiptPayment, this.properties.totalAmount);
            AutoNumericUtil.set(this.inputWalletPayment, 0);
            this.$data.receiptPayment = this.properties.totalAmount.toFixed(2);
            this.$data.walletPayment = "0.00";
        } else if (paymentMethod === 'Wallet') {
            AutoNumericUtil.set(this.inputReceiptPayment, 0);
            AutoNumericUtil.set(this.inputWalletPayment, this.properties.totalAmount);
            this.$data.receiptPayment = "0.00";
            this.$data.walletPayment = this.properties.totalAmount.toFixed(2);
        }
    }

    public mounted() {
        if (this.$data.paymentMethod === "Receipt") {
            this.$data.receiptPayment = this.properties.totalAmount.toFixed(2);
        }

        const dialog = <Vue>this.$refs.content;
        if (dialog) {
            this.inputReceiptPayment = dialog.$el.querySelector("#receipt-payment");
            this.inputWalletPayment = dialog.$el.querySelector("#wallet-payment");
        }

        this.updateFormatter();
    }

    public updateFormatter() {
        setTimeout(() => {
            const options: Options = {
                minimumValue: "0",
                historySize: 0,
                outputFormat: "number",
                digitGroupSeparator: "",
                unformatOnHover: false,
                unformatOnSubmit: false,
            };

            AutoNumericUtil.findOrNew(this.inputWalletPayment, null, options)
                .set(this.$data.walletPayment ?? 0);
            AutoNumericUtil.findOrNew(this.inputReceiptPayment, null, options)
                .set(this.$data.receiptPayment ?? 0);
        }, 150);
    }

    public async uploadReceipt() {
        const attachments: File[] = this.$data.attachments;
        var attachmentIds = [];
        if (attachments.length === 0) {
            var m: any = this.$t("message.please-select-receipt");
            AlertDialogProperties.info(this.alertDialog, null, m);
            this.alertDialog.visible = true;
        } else {
            const service = new CustomerPaymentAttachmentService();

            for (var i = 0; i < attachments.length; i++) {
                var uploader = service.makeUploader(attachments[i]);
                var r = await uploader.upload();
                while (r.continue) {
                    r = await r.continue();
                }
                attachmentIds.push(r.data.id);
            }
        }
        return attachmentIds;
    }

    public async submit(paymentMethod: string) {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            var attachmentIds = [];
            if (paymentMethod !== "Wallet") {
                attachmentIds = await this.uploadReceipt();
                if (attachmentIds.length === 0) {
                    return;
                }
            }

            var shippingId = this.confirmedOrder.shipping?.id;
            const customerOrderIds = this.confirmedOrder.customerOrders
                .filter(x => x.selected).map((x) => x.id);
            const deliveryMethod = this.confirmedOrder.deliveryMethod;
            const voucherNumber = this.confirmedOrder.voucherNumber;
            const receiptPayment = AutoNumericUtil.get(this.inputReceiptPayment);
            const walletPayment = AutoNumericUtil.get(this.inputWalletPayment);

            if (deliveryMethod !== 'Standard-Delivery') {
                shippingId = null;
            }

            const r = await this.customerPaymentService.submit({
                type: 'Checkout',
                shippingId,
                customerOrderIds,
                attachmentIds,
                deliveryMethod,
                paymentMethod,
                receiptPayment,
                walletPayment,
                voucherNumber
            });
            var m: any = this.$t("message.payment-pending-approval", [r.data.uniqueNo]);
            AlertDialogProperties.info(this.alertDialog, null, m);
            this.alertDialog.tag = { command: "checkout-submitted" };
            this.alertDialog.visible = true;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async checkout() {
        const paymentMethod = this.$data.paymentMethod;
        if (paymentMethod === "Receipt") {
            await this.submit("Receipt");
        } else if (paymentMethod === 'Wallet') {
            await this.submit('Wallet');
        } else if (paymentMethod === 'Mixed') {
            await this.submit("Mixed");
        }
    }

    public close() {
        if (!this.$data.saving) {
            this.properties.visible = false;
        }
    }
}

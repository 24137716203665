













































































import { Vue, Component, Prop } from "vue-property-decorator";
import { HomeProperties } from "@/components/home/HomeView.vue";
import { MainProperties } from "./MainView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { AddressDialogProperties } from "./AddressDialogView.vue";
import { DepositDialogProperties } from "./DepositDialogView.vue";
import { CheckoutDialogProperties } from "./CheckoutDialogView.vue";
import { TransferDialogProperties } from "./TransferDialogView.vue";
import EventManager from "@/utilities/EventManager";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import AddressUtil from "@/utilities/AddressUtil";
import CompanyService from "@/services/CompanyService";
import SystemSettingService from "@/services/SystemSettingService";

const WalletView = () => import("./WalletView.vue");
const ConfirmedSummaryView = () => import("./ConfirmedSummaryView.vue");
const ConfirmedOrderBView = () => import("./ConfirmedOrderBView.vue");
const AddressDialogView = () => import("./AddressDialogView.vue");
const CheckoutDialogView = () => import("./CheckoutDialogView.vue");
const DepositDialogView = () => import("./DepositDialogView.vue");
const TransferDialogView = () => import("./TransferDialogView.vue");

class ConfirmedOrderProperties {
    selectAll: boolean = false;
    customerOrders: any[] = [];
    currency: any = {};
    shipping: any = null;
    deliveryMethod: string = "Standard-Delivery";
    allowedDeliveryMethods: string[] = [];
    voucherNumber: string = "";
    voucherNumberApplied: string = "";
    dateTimeFormat: string = null;
    events = new EventManager();
}

export { ConfirmedOrderProperties };

@Component({
    components: {
        WalletView,
        ConfirmedOrderBView,
        ConfirmedSummaryView,
        AddressDialogView,
        CheckoutDialogView,
        DepositDialogView,
        TransferDialogView
    },
})
export default class ConfirmedOrderAView extends Vue {
    @Prop() private home: HomeProperties;
    @Prop() private main: MainProperties;
    @Prop() private alertDialog: AlertDialogProperties;

    private properties = new ConfirmedOrderProperties();
    private addressDialog = new AddressDialogProperties();
    private depositDialog = new DepositDialogProperties();
    private checkoutDialog = new CheckoutDialogProperties();
    private transferDialog = new TransferDialogProperties();
    private systemSettingService = new SystemSettingService();
    private companyService = new CompanyService();

    public created() {
        this.home.events.subscribe("alert-dialog-closed", this.alertDialogClosed);

        this.load();
    }

    public destroyed() {
        this.home.events.remove("alert-dialog-closed", this.alertDialogClosed);
    }

    public async loadCurrency() {
        const r = await this.companyService.currency();
        this.properties.currency = r.data;
    }

    public async loadSystemSetting() {
        const r = await this.systemSettingService.allowedDeliveryMethods();
        const allowed: string[] = r.data;
        this.properties.allowedDeliveryMethods = allowed ?? [];

        if (allowed.length > 0) {
            this.properties.deliveryMethod = allowed[0];
        }
    }

    public async load() {
        try {
            await this.loadSystemSetting();
            await this.loadCurrency();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public async addressSelected(selected: any) {
        selected.address = AddressUtil.build(selected);
        this.properties.shipping = selected;
        this.addressDialog.visible = false;

        await this.properties.events.fire("calculate-shipping-fee");
    }

    public async selectAllChanged() {
		const customerOrders = this.properties.customerOrders;
		customerOrders.forEach(e => {
			Vue.set(e, 'selected', this.properties.selectAll);
		});

        await this.properties.events.fire("calculate-shipping-fee");
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag) {
            if (tag.command === "deposit-submitted") {
                this.depositDialog.visible = false;
                this.properties.voucherNumber = "";
                this.properties.voucherNumberApplied = "";
                await this.properties.events.fire('load');
                await this.properties.events.fire('load-wallet');
            } else if (tag.command === "checkout-submitted") {
                this.checkoutDialog.visible = false;
                this.properties.voucherNumber = "";
                this.properties.voucherNumberApplied = "";
                await this.properties.events.fire('load');
                await this.properties.events.fire('load-wallet');
            } else if (tag.command === "transfer-submitted") {
                this.transferDialog.visible = false;
                await this.properties.events.fire('load');
            }
        }
    }
}
